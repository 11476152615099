//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useEffect } from 'react';

import Environment from '@helper/Environment';

const useMotamoTracking = () => {
    useEffect(() => {
        if (Environment.isProduction()) {
            /* eslint-disable rulesdir/no-shortcut-variable-name, no-underscore-dangle, no-multi-assign */
            const _mtm = window._mtm = window._mtm || [];

            _mtm.push({
                'mtm.startTime': (
                    new Date().getTime()
                ),
                'event':         'mtm.Start',
            });

            (
                function() {
                    const d = document;
                    const g = d.createElement('script');
                    const
                        s   = d.getElementsByTagName('script')[0];
                    g.async = true;
                    g.src   = 'https://firewriter.de/wp-content/uploads/matomo/container_Iap8dY5u.js';
                    s.parentNode.insertBefore(g, s);
                }()
            );
            /* eslint-enable rulesdir/no-shortcut-variable-name, no-underscore-dangle, no-multi-assign */
        }
    }, []);

    return null;
};

export default useMotamoTracking;
